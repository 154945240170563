import React, { useState } from "react";
import Cancel from "../SVGS/icons/Cancel";
import CheckBlue from "../SVGS/icons/CheckBlue";
import Plan from "../SVGS/icons/Plan";
import ScrollAnimation from "react-animate-on-scroll";

const PricingTable = ({ data }) => {
  const pricing = data;
  const [active, setActive] = useState("pro");
  const [subcription, setSubcription] = useState("monthly");
  const benefitMarkup = () => {
    return (
      <div className="omg-card-plan omg-benefit">
        {/* <div className="row benefit-row body-lg">
          <div className="col-8">
            <span className="body-lg">
              Multi-stores{" "}
              {active === "pro" ? (
                <span className="body-lg">
                  <b>(2 stores)</b>
                </span>
              ) : active === "pro-plus" ? (
                <span className="body-lg">
                  <b>(3 stores)</b>
                </span>
              ) : active === "unlimited" ? (
                <span className="body-lg">
                  <b>(4+ stores</b>)
                </span>
              ) : null}{" "}
            </span>
          </div>
          <div className="col-4 float-right">
            {active === "free" || active === "basic" ? (
              <span>
                <Cancel />
              </span>
            ) : (
              <span>
                <CheckBlue />
              </span>
            )}
          </div>
        </div> */}
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Unlimited PayPal Accounts</span>
          </div>
          <div className="col-4 float-right">
            <span>
              <CheckBlue />
            </span>
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Sync digital product</span>
          </div>
          <div className="col-4 float-right">
            {active === "free" ? (
              <span>
                <Cancel />
              </span>
            ) : (
              <span>
                <CheckBlue />
              </span>
            )}
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Courier matching rule </span>
            {active === "basic" ? (
              <span className="body-lg">
                <b>(2 rules)</b>
              </span>
            ) : active === "pro" ? (
              <span className="body-lg">
                <b>(10 rules)</b>
              </span>
            ) : active === "unlimited" || active === "pro-plus" ? (
              <span className="body-lg">
                <b>(unlimited)</b>
              </span>
            ) : null}
          </div>
          <div className="col-4 float-right">
            {active === "free" ? (
              <span>
                <Cancel />
              </span>
            ) : (
              <span>
                <CheckBlue />
              </span>
            )}
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Submit tracking for old orders</span>
          </div>
          <div className="col-4 float-right">
            <span>
              <CheckBlue />
            </span>
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Auto-matching couriers</span>
          </div>
          <div className="col-4 float-right">
            <span>
              <CheckBlue />
            </span>
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Support all checkout gates</span>
          </div>
          <div className="col-4 float-right">
            {active === "free" ? (
              <span>
                <Cancel />
              </span>
            ) : (
              <span>
                <CheckBlue />
              </span>
            )}
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Connect Stripe</span>
          </div>
          <div className="col-4 float-right">
            {active === "free" ? (
              <span>
                <Cancel />
              </span>
            ) : (
              <span>
                <CheckBlue />
              </span>
            )}
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Advanced Customization</span>
          </div>
          <div className="col-4 float-right">
            {active === "free" || active === "basic" ? (
              <span>
                <Cancel />
              </span>
            ) : (
              <span>
                <CheckBlue />
              </span>
            )}
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Priority and Realtime Sync</span>
          </div>
          <div className="col-4 float-right">
            {active === "free" || active === "basic" ? (
              <span>
                <Cancel />
              </span>
            ) : (
              <span>
                <CheckBlue />
              </span>
            )}
          </div>
        </div>
        <div className="row benefit-row">
          <div className="col-8">
            <span className="body-lg">Multi Stripe accounts</span>
          </div>
          <div className="col-4 float-right">
            {active === "free" || active === "basic" ? (
              <span>
                <Cancel />
              </span>
            ) : (
              <span>
                <CheckBlue />
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="omg-pricing-table ">
      <div className="omg-pricing-table-inner">
        <div className="container">
          <div className="omg-pricing-table-title auto-rem">
            <h2 className="display-2 text-center">{pricing.title}</h2>
            <p className="body-lg text-center">{pricing.description}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "3rem",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "30px",
                border: "1px solid rgb(204, 204, 204)",
                width: "fit-content",
              }}
            >
              <button
                onClick={() => {
                  setSubcription("monthly");
                }}
                style={{
                  height: "50px",
                  backgroundColor:
                    subcription === "monthly" ? "#006eba" : "transparent",
                  color: subcription === "monthly" ? "white" : "black",
                  transition: "all 0.2s ease-in-out 0s",
                  textTransform: "uppercase",
                  padding: "15px 30px",
                  border: "none",
                  borderRadius: "30px",
                  fontWeight: "500",
                }}
              >
                Monthly
              </button>
              <button
                onClick={() => {
                  setSubcription("yearly");
                }}
                style={{
                  height: "50px",
                  backgroundColor:
                    subcription === "yearly" ? "#006eba" : "transparent",
                  color: subcription === "yearly" ? "white" : "black",
                  transition: "all 0.2s ease-in-out 0s",
                  textTransform: "uppercase",
                  padding: "15px 30px",
                  border: "none",
                  borderRadius: "30px",
                  fontWeight: "500",
                }}
              >
                Yearly{" "}
                <span
                  style={{
                    color:
                      subcription === "yearly"
                        ? "rgb(255, 236, 67)"
                        : "rgb(255, 89, 89)",
                    fontWeight: "600",
                  }}
                >
                  Save 30%
                </span>
              </button>
            </div>
          </div>

          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <div className="row">
              <div className="col-md-6 omg-plans">
                {pricing.content.map((item, index) => (
                  <div
                    className={
                      item.class === active
                        ? `omg-card-plan active-card`
                        : "omg-card-plan"
                    }
                    onClick={() => setActive(item.class)}
                    key={index}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-8">
                        <div className="grid-two">
                          <Plan />
                          <div>
                            <div className="omg-badge-container">
                              <div className="title-small">{item.plan}</div>
                              {item.badge ? (
                                <div className="omg-badge">{item.badge}</div>
                              ) : null}
                            </div>
                            <span className="body-lg">{item.limit}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 float-right body-lg">
                        {subcription !== "monthly" && (
                          <span
                            style={{
                              textDecoration:
                                item.class !== "free" && "line-through",
                              marginRight: "8px",
                              color:
                                item.class === active
                                  ? "rgb(255, 236, 67)"
                                  : "rgb(228, 77, 84)",
                            }}
                          >
                            {item.price}
                          </span>
                        )}
                        <b>
                          {subcription === "monthly"
                            ? item.price
                            : item.yearlyPrice}
                        </b>
                        <span>/month</span>
                      </div>
                    </div>
                    {item.class === active &&
                    typeof window !== "undefined" &&
                    window.innerWidth < 768 ? (
                      <div
                        className="mb-show"
                        style={{
                          maxHeight: "30rem",
                          transition: "max-height 1s ease",
                          overflow: "hidden",
                        }}
                      >
                        {benefitMarkup()}
                      </div>
                    ) : (
                      <div
                        className="mb-show"
                        style={{
                          maxHeight: "0rem",
                          transition: "max-height 1s ease",
                          overflow: "hidden",
                        }}
                      >
                        {benefitMarkup()}
                      </div>
                    )}
                  </div>
                ))}
                {/* <div className="omg-badge">Recommended</div> */}
              </div>
              <div className="col-md-6 mb-hide">{benefitMarkup()}</div>
            </div>
          </ScrollAnimation>
        </div>
        <div className="omg-plan-table-clip-path"></div>
      </div>
    </div>
  );
};

export default PricingTable;
